<template>
    <div>
     <v-menu bottom left>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="d-none d-sm-block"
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                color="blue lighten-2 ml-3"
              >
                <v-icon>{{ iconSort }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.sort-data") }}</span>
          </v-tooltip>
        </template>
  
        <v-list>
          <v-list-item
            v-for="(item, i) in menus"
            :key="i"
            class="cursor-pointer memo-header-menu"
          >
            <v-icon class="mr-3">{{ item.icon }}</v-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>
  
  <script>
  import {
    mdiDotsVertical,
    mdiCheckCircleOutline,
    mdiSort
  } from "@mdi/js";
  import toolbarMenu from "@/constants/toolbarmenu"
  
  export default {
    name:"header-right",
    data : () => ({
      iconMenu: mdiDotsVertical,
      iconCheck : mdiCheckCircleOutline,
      iconSort : mdiSort,
      menus: toolbarMenu.inboxSortMenu
    })
  };
  
  </script>